import { yupResolver } from '@hookform/resolvers/yup';
import { cardNumberValidator } from '@root/functions/cardNumberValidator';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { validateDate } from './validateDate';

type UserValidation = {
  noValidateExpiry: boolean;
  noValidateCardNumber: boolean;
  validateHolder?: boolean;
  validatePayer?: boolean;
  validateAmount?: boolean;
  validateEmail?: boolean;
  validatePhone?: boolean;
  validateCardOwnerCheckbox?: boolean;
  payCard?: boolean;
  payPhone?: boolean;
  validationText?: {
    cardNumber: Record<string, string>;
    expiry: Record<string, string>;
    cvv: Record<string, string>;
    holder: Record<string, string>;
    email: Record<string, string>;
    confirmCardOwner: Record<string, string>;
  };
};

export const useValidation = (
  values: UserValidation = {
    noValidateExpiry: false,
    noValidateCardNumber: false,
    validateHolder: false,
    validatePayer: false,
    validateAmount: false,
    validateEmail: false,
    validateCardOwnerCheckbox: false,
    validatePhone: false,
    payCard: false,
    payPhone: false
  }
) => {
  const [isValidate, setIsValidate] = useState(true);
  const { t } = useTranslation(['error']);
  const { validationText } = values;

  const cardShema = yup
    .string()
    .required(
      validationText
        ? validationText.cardNumber.required
        : t('error:fill_fields')
    )
    .test(
      'lunaValidation',
      validationText
        ? validationText.cardNumber.lunaValidation
        : t('error:not_valid_card'),
      (value) => cardNumberValidator(value)
    );

  const cardOwnerCheckboxSchema = yup
    .bool()
    .oneOf([true], 'HELLO WORLD')
    .required('HELLO WORLD');

  const expiryShema = yup
    .string()
    .required(
      validationText ? validationText.expiry.required : t('error:fill_fields')
    )
    .matches(
      /^\d{2}\/\d{2}$/,
      validationText
        ? validationText.expiry.matches
        : t('error:not_valid_format')
    )
    .test(
      'expiryValidation',
      validationText
        ? validationText.expiry.expiryValidation
        : t('error:not_valid_date'),
      (value) => validateDate(value)
    );

  const cvvShema = yup
    .string()
    .required(
      validationText ? validationText.cvv.required : t('error:fill_fields')
    )
    .matches(
      /^\d{3}$/,
      validationText ? validationText.cvv.matches : t('error:not_valid_cvv')
    );

  const payerShema = yup.string().required(t('error:fill_fields'));
  const phoneSchema = yup
    .string()
    .required(t('error:fill_fields'))
    .test('valid-length', t('error:invalid_length'), (value) => {
      if (!value) return false;
      const formattedValue = value.replace(/\s/g, '');
      return formattedValue.length >= 12;
    })
    .test('valid-code', t('error:invalid_code'), (value) => {
      if (!value) return false;
      if (value.replace(/\s/g, '').length < 12) return true;
      const codeRegex =
        /^\+7\s?(700|701|702|705|707|708|747|771|775|776|777|778)/;
      return codeRegex.test(value);
    })
    .matches(
      /^\+7\s?(700|701|702|705|707|708|747|771|775|776|777|778)\s?\d{3}\s?\d{2}\s?\d{2}$/,
      t('error:invalid_phone')
    );

  const holderShema = yup
    .string()
    .required(t('error:fill_fields'))
    .matches(
      /^((?:[A-Za-z]+ ?){1,3})$/,
      validationText
        ? validationText.holder.matches
        : t('error:not_valid_holder')
    );

  const amountShema = yup.string().required(t('error:fill_fields'));
  const emailShema = yup.string().email(t('error:not_valid_email'));

  const getValidationShape = () => {
    const validationShape: {
      cvv?: typeof cvvShema;
      expiry?: typeof expiryShema;
      card_number?: typeof cardShema;
      payer?: typeof payerShema;
      holder?: typeof holderShema;
      amount?: typeof amountShema;
      email?: typeof emailShema;
      cardOwnerCheckbox?: typeof cardOwnerCheckboxSchema;
      phoneNumber?: typeof phoneSchema;
    } = {};

    if (values.payCard !== false) {
      validationShape.cvv = cvvShema;
      if (!values.noValidateExpiry) {
        validationShape.expiry = expiryShema;
      }
      if (!values.noValidateCardNumber) {
        validationShape.card_number = cardShema;
      }
      if (values.validatePayer) {
        validationShape.payer = payerShema;
      }
      if (values.validateCardOwnerCheckbox) {
        validationShape.cardOwnerCheckbox = cardOwnerCheckboxSchema;
      }
      if (values.validateHolder) {
        validationShape.holder = holderShema;
      }
      if (values.validateAmount) {
        validationShape.amount = amountShema;
      }
      if (values.validateEmail) {
        validationShape.email = emailShema;
      }
    }

    if (values.validatePhone && values.payCard !== true) {
      validationShape.phoneNumber = phoneSchema;
    }

    return validationShape;
  };

  const schema = yup.object().shape(getValidationShape());

  const cardValidation = yupResolver(schema);

  return {
    cardValidation: isValidate ? cardValidation : undefined,
    setIsValidate,
    isValidate
  };
};

const excludeNumberCards = [
  '4414414141414141',
  '5747747474747474',
  '4414414141414142',
  '5747747474747475'
];

export const excludeValidationForSomeCards = (
  cardNumber: string | undefined
) => {
  if (!cardNumber) return false;
  const parsedCardNumber = cardNumber.replace(/[\s,_]/g, '');
  if (parsedCardNumber.length < 16) return false;
  return excludeNumberCards.some(
    (cardNumber) => cardNumber === parsedCardNumber
  );
};

import CustomMainVerify from '@comp/CustomVerify';
import CustomFailed from '@root/components/CustomFailed';
import CustomSuccess from '@root/components/CustomSuccess';
import { formVerifyData, getNewPayContentData } from '@root/constants/formData';
import {
  newpayVerify,
  newpayVerifyFailed,
  newpayVerifySuccess
} from '@root/constants/paths';

const { main } = getNewPayContentData();

export const getNewpayVerifyRoutes = () => [
  {
    path: newpayVerify(),
    element: (
      <CustomMainVerify
        mainContent={main}
        formData={formVerifyData}
        redirectPaths={{
          success: newpayVerifySuccess,
          failed: newpayVerifyFailed
        }}
      />
    )
  },
  {
    path: newpayVerifySuccess(),
    element: <CustomSuccess />
  },
  {
    path: newpayVerifyFailed(),
    element: <CustomFailed />
  }
];

import { getUserInfo, UserInfoOptions } from '@root/api/getUserInfo';
import { convertCardMask } from '@root/utils/convertors';
import { getFormattedPrice } from '@root/utils/getFormattedPriceLocale';
import React from 'react';

export type UserData = {
  payAmount: string;
  fee: string;
  creditId: number;
  cardMask?: string;
  expiry?: string;
  holder?: string;
  cvv?: string;
  description: string;
  merchantHost?: string;
  isExtension?: boolean;
  apayEnabled: boolean;
  gpayEnabled: boolean;
  shouldDisplayAgreementDetails?: boolean;
  shouldDisplayBackToSiteButton?: boolean;
  merchantLink: string;
  options: Partial<UserInfoOptions>;
  fullAmount: string;
  token?: string;
  tranType?: string;
  editCardMask?: boolean;
  phonePaymentEnabled?: boolean;
};

const getAmount = (amount: number) => (amount ? amount / 100 : 0);

export const useUserData = (id: string | undefined) => {
  const [userData, setUserData] = React.useState<null | UserData>(null);

  React.useEffect(() => {
    if (!id) return;
    getUserInfo(id).then((data) =>
      setUserData({
        description: data.description,
        merchantHost: data.merchantHost,
        payAmount: getFormattedPrice(getAmount(data.amount)),
        fullAmount: getFormattedPrice(getAmount(data.fullAmount)),
        isExtension: data.isExtension,
        creditId: data.tranId || 0,
        cardMask: data.pan && convertCardMask(data.pan),
        expiry: data.expiry || '',
        fee: getFormattedPrice(getAmount(data.fee)),
        options: data.options || {},
        token: data.token,
        apayEnabled: data.apayEnabled,
        gpayEnabled: data.gpayEnabled,
        shouldDisplayAgreementDetails: data.shouldDisplayAgreementDetails,
        shouldDisplayBackToSiteButton: data.shouldDisplayBackToSiteButton,
        tranType: data.tranType,
        merchantLink: data.merchantLink,
        holder: data.holder,
        cvv: data.cvv,
        phonePaymentEnabled: data.phonePaymentEnabled
      })
    );
  }, [id]);

  return { userData };
};

import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import '../UserAgreement/style.scss';

interface UserAgreementProps {
  payPhone?: boolean;
}

const NewUserAgreement: FC<UserAgreementProps> = () => {
  const { t } = useTranslation();

  return (
    <div className="user-agreement">
      <p className="user-agreement__text">
        {t('common:take_btn')} {`"${t('common:confirm')}"`}, вы принимаете
        условия &nbsp;
        <a
          target="_blank"
          rel="noreferrer"
          href="https://newpay.kz/page-docs.html?tab=1"
          className="user-agreement__link"
        >
          {t('common:oferta')}
        </a>
        {/* &nbsp;
        <a
          target="_blank"
          rel="noreferrer"
          href="https://newpay.kz/page-docs.html?tab=2"
          className="user-agreement__link"
        >
          {t('common:private_policy')}
        </a>
        &nbsp;
        {!payPhone && (
          <a
            target="_blank"
            rel="noreferrer"
            href="https://newpay.kz/page-docs.html?tab=3"
            className="user-agreement__link"
          >
            {t('common:private_offer')}
          </a> */}
        {/* )} */}
      </p>
    </div>
  );
};

export default NewUserAgreement;

import { Main } from '@pages/creditplus/Main';
import { CardInfo } from '@pages/creditplus/CardInfo';
import {
  creditPlusPay,
  creditPlusPayFailed,
  creditPlusPaySuccess
} from '@root/constants/paths';
import CustomSuccess from '@root/components/CustomSuccess';
import CustomFailed from '@root/components/CustomFailed';
import {
  formPayData,
  getCreditplusContentData
} from '@root/constants/formData';

const { main } = getCreditplusContentData();

export const getCreditplusPayRoutes = () => [
  {
    path: creditPlusPay(),
    element: (
      <Main
        mainContent={main}
        formData={formPayData}
        redirectPaths={{
          success: creditPlusPaySuccess,
          failed: creditPlusPayFailed
        }}
      >
        <CardInfo userData={undefined as never} />
      </Main>
    )
  },
  {
    path: creditPlusPaySuccess(),
    element: <CustomSuccess />
  },
  {
    path: creditPlusPayFailed(),
    element: <CustomFailed />
  }
];

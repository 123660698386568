import creditCardType from 'credit-card-type';

export const cardMaskByNumber = (value: string, masked: boolean): string => {
  if (masked) {
    return '9999 99** **** 9999';
  }

  const defaultMask = '9999 9999 9999 9999';

  const cardNumber = value.replace(/\D/g, '').slice(0, 16);

  if (cardNumber === '') {
    return defaultMask;
  }

  const types = creditCardType(cardNumber);

  if (types.length === 0) {
    return defaultMask;
  }

  let len: number | undefined;
  let maxLen: number = cardNumber.length;
  let gaps: number[] = [];

  types.forEach((currentType) => {
    const lengths = currentType.lengths.filter(
      (length) => length >= cardNumber.length
    );

    const minTypeLength = Math.min(...lengths);
    maxLen = Math.max(...lengths, maxLen);

    if (len === undefined || minTypeLength < len) {
      len = minTypeLength;
      gaps = currentType.gaps;
    }
  });

  if (len === undefined) {
    len = Math.max(16, cardNumber.length);
    gaps = [4, 8, 12];
  }

  let mask: string = '9'.repeat(Math.min(len, 16));

  gaps
    .slice()
    .reverse()
    .forEach((gap) => {
      if (gap < mask.length) {
        mask = [mask.slice(0, gap), mask.slice(gap)].join(' ');
      }
    });

  return mask;
};

import {
  getCreditplusContentData,
  getNewPayContentData
} from '@root/constants/formData';
import { CREDIT_PLUS_MERCHANT } from '@root/constants/paths';
import { lazy } from 'react';
import { getCreditplusPayRoutes } from './creditplusPayRoutes';
import { getCreditPlusVerifyRoutes } from './creditplusVerifyRoutes';

const Layout = lazy(() => import('@pages/creditplus/Layout'));

let { header } = getCreditplusContentData();

const currentPath = location.pathname.split('/')[2];

if (currentPath === 'verify') {
  header = getNewPayContentData().header;
}

export const creditplusRoutes = {
  path: CREDIT_PLUS_MERCHANT,
  element: <Layout headerContent={header} />,
  children: [...getCreditPlusVerifyRoutes(), ...getCreditplusPayRoutes()]
};

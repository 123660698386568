import CustomForm from '@comp/CustomForm';
import { useUserData } from '@hooks/useUserData';
import { ContentData } from '@root/constants/formData/types';
import { OnSubmitParams, useOnSubmit } from '@root/hooks/useOnSubmit';
import style from '@root/scss/credit7/index.module.scss';
import React from 'react';
import { Oval } from 'react-loader-spinner';
import { useParams } from 'react-router-dom';

type MainK7Props = {
  mainContent: ContentData['main'];
  children?: React.ReactNode;
  formData: {
    title: string;
  };
  redirectPaths: OnSubmitParams['redirectPaths'];
};

export const Main = (props: MainK7Props) => {
  const { redirectPaths, children, formData, mainContent } = props;
  const { id } = useParams();
  const { userData } = useUserData(id);
  const { onSubmit } = useOnSubmit({
    redirectPaths,
    merchant:
      userData && userData.options.merchant ? userData.options.merchant : '',
    token: userData ? userData.token : undefined
  });
  if (userData) {
    return (
      <CustomForm
        onSubmit={onSubmit}
        formData={formData}
        mainContent={mainContent}
        expiry={userData.expiry}
        cardMask={userData.cardMask}
        userData={userData}
        oneCreditCredit365Beecredit={true}
      >
        {children
          ? React.Children.map(children, (child) => {
              if (React.isValidElement<{ userData: typeof userData }>(child)) {
                return React.cloneElement(child, { userData });
              }
            })
          : children}
      </CustomForm>
    );
  }

  return (
    <section className={style.blockForm}>
      <div className={style.container_540}>
        <Oval wrapperClass={style['centred-block']} />
      </div>
    </section>
  );
};

import { aCreditRoutes } from './acreditRoute';
import { oneCreditRoutes } from './onecreditRoute';
import { credit365Routes } from './credit365Route';
import { beecreditRoutes } from './beecreditRoute';
import { installmentRoutes } from './installmentRoute';
import { automoneyRoutes } from './automoneyRoute';
import { customRoutes } from './customRoute';
import { error404Route } from './error404Route';
import { statusPageRoute } from './StatusPageRoute';
import { newpayRoutes } from './newpayRoute';
import { tengebaiRoutes } from './tengebaiRoute';
import { findomRoutes } from './findomRoute';
import { creditplusRoutes } from './creditplusRoute';

export const routes = [
  customRoutes,
  aCreditRoutes,
  credit365Routes,
  beecreditRoutes,
  newpayRoutes,
  installmentRoutes,
  automoneyRoutes,
  oneCreditRoutes,
  tengebaiRoutes,
  creditplusRoutes,
  findomRoutes,
  statusPageRoute,
  error404Route
];

import NewMainVerify from '@comp/NewVerify/newVerifyMain';
import icon from '@images/newDesingVerify/oneCredit.svg';
import CustomFailed from '@root/components/CustomFailed';
import CustomSuccess from '@root/components/CustomSuccess';
import {
  formVerifyData,
  getOneCreditContentData
} from '@root/constants/formData';
import {
  oneCreditVerify,
  oneCreditVerifyFailed,
  oneCreditVerifySuccess
} from '@root/constants/paths';

const { main } = getOneCreditContentData();

export const getOneCreditVerifyRoutes = () => [
  {
    path: oneCreditVerify(),
    element: (
      <NewMainVerify
        mainContent={main}
        formData={formVerifyData}
        merchantIcon={icon}
        redirectPaths={{
          success: oneCreditVerifySuccess,
          failed: oneCreditVerifyFailed
        }}
      />
    )
  },
  {
    path: oneCreditVerifySuccess(),
    element: <CustomSuccess />
  },
  {
    path: oneCreditVerifyFailed(),
    element: <CustomFailed />
  }
];

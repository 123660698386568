import CustomMainVerify from '@comp/CustomVerify';
import CustomFailed from '@root/components/CustomFailed';
import CustomSuccess from '@root/components/CustomSuccess';
import {
  formVerifyData,
  getCreditplusContentData
} from '@root/constants/formData';
import {
  creditPlusVerify,
  creditPlusVerifyFailed,
  creditPlusVerifySuccess
} from '@root/constants/paths';

const { main } = getCreditplusContentData();

export const getCreditPlusVerifyRoutes = () => [
  {
    path: creditPlusVerify(),
    element: (
      <CustomMainVerify
        mainContent={main}
        formData={formVerifyData}
        redirectPaths={{
          success: creditPlusVerifySuccess,
          failed: creditPlusVerifyFailed
        }}
      />
    )
  },
  {
    path: creditPlusVerifySuccess(),
    element: <CustomSuccess />
  },
  {
    path: creditPlusVerifyFailed(),
    element: <CustomFailed />
  }
];

import NewMainVerify from '@comp/NewVerify/newVerifyMain';
import icon from '@images/newDesingVerify/credit365.svg';
import CustomFailed from '@root/components/CustomFailed';
import CustomSuccess from '@root/components/CustomSuccess';
import {
  formVerifyData,
  getCredit365ContentData
} from '@root/constants/formData';
import {
  credit365Verify,
  credit365VerifyFailed,
  credit365VerifySuccess
} from '@root/constants/paths';

const { main } = getCredit365ContentData();

export const getCredit365VerifyRoutes = () => [
  {
    path: credit365Verify(),
    element: (
      <NewMainVerify
        mainContent={main}
        formData={formVerifyData}
        merchantIcon={icon}
        redirectPaths={{
          success: credit365VerifySuccess,
          failed: credit365VerifyFailed
        }}
      />
    )
  },
  {
    path: credit365VerifySuccess(),
    element: <CustomSuccess />
  },
  {
    path: credit365VerifyFailed(),
    element: <CustomFailed />
  }
];

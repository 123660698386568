/* eslint-disable max-lines */
import {
  excludeValidationForSomeCards,
  useValidation
} from '@root/utils/validation';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import CustomField from '../CustomField';
// import CustomCheckBox from '../CustomCheckbox';
import CustomWrapperFields from '@comp/CustomWrapperFields';
import PayButtons from '@comp/PayButtons';
import RawFields from '@comp/RawFields';
import { OnSubmit } from '@hooks/useOnSubmit';
import UserAgreement from '@root/components/UserAgreement';
import { ContentData } from '@root/constants/formData/types';
import { UserData } from '@root/hooks/useUserData';
import { useTranslation } from 'react-i18next';
import { Oval } from 'react-loader-spinner';
//import CustomCheckBox from '@comp/CustomCheckbox';
import { Icons } from '@comp/IconsForm/Icons';
import { IconsPhone } from '@comp/IconsForm/iconsPayPhone';
import { Security } from '@comp/Security';
import backBtn from '@images/custom/backbtnCustom.svg';
import cardIcon from '@images/custom/card.svg';
import phoneIcon from '@images/custom/phone.svg';
import cvvIcon from '@images/onecredit/cvvIcon.svg';
import { cardMaskByNumber } from '@root/functions/cardMaskByNumber';
import style from '@scss/credit7/index.module.scss';
import { PaymentContent } from './paymentContent';

export type CustomFormInputs = {
  card_number: string;
  expiry: string;
  cvv: string;
  save: string;
  holder: string;
  email: string;
  phoneNumber: string;
};

type CustomFormProps = {
  userData: UserData;
  onSubmit: OnSubmit;
  children: React.ReactNode | undefined;
  formData: {
    title: string;
  };
  mainContent: ContentData['main'];
  cardMask?: string;
  expiry?: string;
  oneCreditCredit365Beecredit?: boolean;
};

const CustomForm = (props: CustomFormProps) => {
  const {
    userData: {
      expiry,
      cardMask,
      apayEnabled,
      gpayEnabled,
      options,
      shouldDisplayBackToSiteButton,
      merchantLink,
      fee,
      fullAmount,
      payAmount,
      tranType,
      merchantHost,
      editCardMask,
      phonePaymentEnabled
    },
    oneCreditCredit365Beecredit,
    onSubmit,
    children
  } = props;

  const [masked, setMasked] = useState(Boolean(cardMask));
  const [cardNumberMask, setCardNumberMask] = useState(
    cardMask ? '9999 99** **** 9999' : '9999 9999 9999 9999*'
  );
  const [payCard, setPayCard] = useState(false);
  const [payPhone, setPayPhone] = useState(false);
  const [openForm, setOpenForm] = useState(false);

  const { cardValidation, isValidate, setIsValidate } = useValidation({
    noValidateExpiry: masked,
    noValidateCardNumber: masked,
    validateEmail: true,
    validateHolder: true,
    validatePhone: true,
    payCard,
    payPhone
  });

  const {
    handleSubmit,
    clearErrors,
    control,
    formState: { errors, isSubmitting },
    watch,
    setValue
  } = useForm<CustomFormInputs>({
    resolver: cardValidation,
    defaultValues: {
      save: 'true',
      card_number: cardMask || '',
      expiry: expiry || '',
      cvv: '',
      holder: '',
      phoneNumber: ''
    }
  });

  const { t } = useTranslation(['common']);

  React.useEffect(() => {
    const subscription = watch((value) => {
      if (excludeValidationForSomeCards(value['card_number'])) {
        setIsValidate(false);
      } else if (!isValidate) {
        setIsValidate(true);
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, isValidate]);

  useEffect(() => {
    if (cardValidation === undefined) {
      clearErrors();
    }
  }, [cardValidation]);
  const watchFields = watch('card_number');

  React.useEffect(() => {
    if (masked && watchFields != cardMask) {
      setMasked(false);
      setValue('card_number', '');
      setValue('expiry', '');
    }
  }, [masked, watchFields, cardMask]);

  React.useEffect(() => {
    const mask = cardMaskByNumber(watchFields, masked);
    if (mask != cardNumberMask) {
      setCardNumberMask(mask);
    }
  }, [watchFields]);

  const onSubmitForm = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    handleSubmit(onSubmit)();
  };

  const payCardChange = () => {
    setOpenForm((prevState) => !prevState);
    setPayCard((prevState) => !prevState);
  };
  const payPhoneChange = () => {
    setOpenForm((prevState) => !prevState);
    setPayPhone((prevState) => !prevState);
  };
  const backArrow = () => {
    if (payCard) return payCardChange();
    if (payPhone) return payPhoneChange();
    window.location.replace(merchantLink);
  };

  return (
    <section className="cardProof">
      <form className="container-s" onSubmit={onSubmitForm}>
        <PaymentContent
          isFullLoanPayment={options.isFullLoanPayment}
          oneCreditCredit365Beecredit={oneCreditCredit365Beecredit}
          shouldDisplayBackToSiteButton={shouldDisplayBackToSiteButton || false}
          backArrow={backArrow}
          payCard={payCard || false}
          payPhone={payPhone || false}
          isExtension={options.isExtension}
          backBtn={backBtn}
          style={style}
        >
          {children}
        </PaymentContent>

        {!payCard && !payPhone && (
          <>
            {tranType == 'c2a' && (
              <PayButtons
                isApple={apayEnabled}
                isGoogle={gpayEnabled}
                onSubmit={onSubmit}
                payInfo={{
                  fee,
                  clientName: options.clientName,
                  agreementId: options.agreementId,
                  merchantHost,
                  fullAmount,
                  payAmount
                }}
              />
            )}

            <div className={style.new_btn_phone_container}>
              <button onClick={payCardChange}>
                <img src={cardIcon} alt="" />
                {t('payCard')}
              </button>
              {phonePaymentEnabled && tranType === 'c2a' && (
                <>
                  <button onClick={payPhoneChange}>
                    <img src={phoneIcon} alt="" />
                    {t('payPhone')}
                  </button>
                </>
              )}
            </div>
          </>
        )}

        {openForm && (
          <>
            {payCard && (
              <div>
                <CustomField
                  disabled={Boolean(editCardMask)}
                  control={control}
                  name={'card_number'}
                  label={t('common:card_number')}
                  mask={cardNumberMask}
                  error={errors['card_number']}
                  inputMode={'numeric'}
                  placeHolder="0000 0000 0000 0000"
                  maskChar={null}
                />
                <div className="input-row">
                  <CustomField
                    disabled={masked}
                    control={control}
                    name={'expiry'}
                    label={t('common:expiry')}
                    mask={'99/99'}
                    error={errors['expiry']}
                    inputMode={'numeric'}
                    placeHolder="ММ/ГГ"
                  />
                  <CustomField
                    control={control}
                    name={'cvv'}
                    label="CVV"
                    mask={'999'}
                    error={errors['cvv']}
                    inputMode={'numeric'}
                    type="password"
                    placeHolder="000"
                    maskChar={''}
                    icon={cvvIcon}
                  />
                </div>
                <CustomWrapperFields
                  label={t('common:holder')}
                  error={errors['holder']}
                >
                  <RawFields
                    placeholder={t('common:holder_placeholder')}
                    control={control}
                    name={'holder'}
                    inputMode={'text'}
                    oneCreditCredit365Beecredit={oneCreditCredit365Beecredit}
                  />
                </CustomWrapperFields>
              </div>
            )}

            {payPhone && (
              <div style={{ marginTop: '20px' }}>
                <CustomField
                  control={control}
                  name={'phoneNumber'}
                  label={t('phoneNumber')}
                  mask={'+7 999 999 99 99'}
                  error={errors['phoneNumber']}
                  inputMode={'numeric'}
                  type="text"
                  placeHolder="+7 (000) 000 00 00"
                  maskChar={''}
                />
              </div>
            )}

            <div className="button-spin">
              <button
                disabled={isSubmitting}
                className={`${style.btn} ${style.btn__purple}`}
                type="submit"
              >
                {t('common:confirm')}
              </button>
              {isSubmitting && (
                <Oval wrapperClass="button-spin__spinner" strokeWidth={5} />
              )}
            </div>

            <UserAgreement payPhone={payPhone} />
            {/* <PayImgBox /> */}
            {payCard && <Icons />}
            {payPhone && <IconsPhone />}
            <Security />
          </>
        )}
      </form>
    </section>
  );
};

export default CustomForm;

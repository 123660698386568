import CustomMainVerify from '@comp/CustomVerify';
import CustomFailed from '@root/components/CustomFailed';
import CustomSuccess from '@root/components/CustomSuccess';
import { formVerifyData, getFindomContentData } from '@root/constants/formData';
import {
  findomVerify,
  findomVerifyFailed,
  findomVerifySuccess
} from '@root/constants/paths';

const { main } = getFindomContentData();

export const getFindomVerifyRoutes = () => [
  {
    path: findomVerify(),
    element: (
      <CustomMainVerify
        mainContent={main}
        formData={formVerifyData}
        redirectPaths={{
          success: findomVerifySuccess,
          failed: findomVerifyFailed
        }}
      />
    )
  },
  {
    path: findomVerifySuccess(),
    element: <CustomSuccess />
  },
  {
    path: findomVerifyFailed(),
    element: <CustomFailed />
  }
];

import CustomWrapperFields from '@comp/CustomWrapperFields';
import { Icons } from '@comp/IconsForm/Icons';
import RawFields from '@comp/RawFields';
import { Security } from '@comp/Security';
import { OnSubmit } from '@hooks/useOnSubmit';
import { ContentData } from '@root/constants/formData/types';
import { cardMaskByNumber } from '@root/functions/cardMaskByNumber';
import { UserData } from '@root/hooks/useUserData';
import {
  excludeValidationForSomeCards,
  useValidation
} from '@root/utils/validation';
import style from '@scss/newVerifyStyle/index.module.scss';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Oval } from 'react-loader-spinner';
import { bankList, getCardType } from './helper';
import NewField from './NewField';
import NewUserAgreement from './NewVerifyUserAgreement';
export type CustomFormInputs = {
  card_number: string;
  expiry: string;
  cvv: string;
  save: string;
  holder: string;
  email: string;
};

type CustomFormProps = {
  userData: UserData;
  onSubmit: OnSubmit;
  children: React.ReactNode | undefined;
  formData: {
    title: string;
  };
  mainContent: ContentData['main'];
  cardMask?: string;
  expiry?: string;
  merchantIcon?: string;
};

const NewFormVerify = (props: CustomFormProps) => {
  const {
    userData: { expiry, cardMask, editCardMask },
    onSubmit,
    merchantIcon
  } = props;

  const [masked, setMasked] = useState(Boolean(cardMask));
  const [cardNumberMask, setCardNumberMask] = useState(
    cardMask ? '9999 99** **** 9999' : '9999 9999 9999 9999*'
  );
  const [isAgreed, setIsAgreed] = useState(true);
  const [showCheckboxError, setShowCheckboxError] = useState(false);
  const [typeCard, setTypeCard] = useState('');
  const [bankCard, setBankCard] = useState('');

  const { cardValidation, isValidate, setIsValidate } = useValidation({
    noValidateExpiry: masked,
    noValidateCardNumber: masked,
    validateEmail: true,
    validateHolder: true
  });

  const {
    handleSubmit,
    clearErrors,
    control,
    formState: { errors, isSubmitting },
    watch,
    setValue
  } = useForm<CustomFormInputs>({
    resolver: cardValidation,
    defaultValues: {
      save: 'true',
      card_number: cardMask || '',
      expiry: expiry || '',
      cvv: '',
      holder: ''
    }
  });

  const { t } = useTranslation(['common']);
  const watchFields = watch('card_number');

  React.useEffect(() => {
    const subscription = watch((value) => {
      if (excludeValidationForSomeCards(value['card_number'])) {
        setIsValidate(false);
      } else if (!isValidate) {
        setIsValidate(true);
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, isValidate]);

  useEffect(() => {
    if (cardValidation === undefined) {
      clearErrors();
    }
  }, [cardValidation]);

  React.useEffect(() => {
    if (masked && watchFields != cardMask) {
      setMasked(false);
      setValue('card_number', '');
      setValue('expiry', '');
    }
    const cardTypeDetected = getCardType(watchFields);
    setTypeCard(cardTypeDetected);
    const cleanCardNumber = watchFields.replace(/\s+/g, '');
    const bankCode = cleanCardNumber.slice(0, 6);
    const bankName = bankList[bankCode] || '';
    setBankCard(bankName);
  }, [masked, watchFields, cardMask]);

  React.useEffect(() => {
    const mask = cardMaskByNumber(watchFields, masked);
    if (mask != cardNumberMask) {
      setCardNumberMask(mask);
    }
  }, [watchFields]);

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsAgreed(event.target.checked);
    if (event.target.checked) {
      setShowCheckboxError(false);
    }
  };

  const onSubmitForm = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!isAgreed) {
      setShowCheckboxError(true);
      return;
    }
    handleSubmit(onSubmit)();
  };

  return (
    <section className="cardProof">
      <form className={style.container_new} onSubmit={onSubmitForm}>
        <div className={style.icon_merchant}>
          <img src={merchantIcon} alt="" />
        </div>
        <div className={style.newBlockVerify}>
          <div className={style.card1}>
            {!bankCard && !typeCard && (
              <div className={style.titleVerifyNew}>{t('bank-card')}</div>
            )}
            <div className={style.icon_block}>
              <img src={bankCard} alt="" />
              <img src={typeCard} alt="" />
            </div>
            <div className={style.verify_form_input}>
              <div className={style.card_input}>
                <NewField
                  disabled={Boolean(editCardMask)}
                  control={control}
                  name={'card_number'}
                  label={t('card_number')}
                  mask={cardNumberMask}
                  error={errors['card_number']}
                  inputMode={'numeric'}
                  placeHolder="0000 0000 0000 0000"
                  maskChar={null}
                />
              </div>

              <div className={style.expiry_input}>
                <NewField
                  disabled={masked}
                  control={control}
                  name={'expiry'}
                  label={t('ММ/ГГ')}
                  mask={'99/99'}
                  error={errors['expiry']}
                  inputMode={'numeric'}
                  placeHolder="ММ/ГГ"
                />
              </div>
            </div>
            <div className={style.holder_input}>
              <CustomWrapperFields
                label={t('common:holder')}
                error={errors['holder']}
                newVerify={true}
              >
                <RawFields
                  placeholder={t('common:holder_placeholder')}
                  control={control}
                  name={'holder'}
                  inputMode={'text'}
                  oneCreditCredit365Beecredit={true}
                />
              </CustomWrapperFields>
            </div>
          </div>
          <div className={style.card2}>
            <div className={style.cvv_bgc}></div>
            <div className={style.cvv_input}>
              <div className={`small-input ${style.small_input_new}`}>
                <NewField
                  control={control}
                  name={'cvv'}
                  label="CVV"
                  mask={'999'}
                  error={errors['cvv']}
                  inputMode={'numeric'}
                  type="password"
                  placeHolder="000"
                  maskChar={''}
                />
                <p
                  className={`${style.cvv_helper_text} ${
                    errors.cvv ? style.cvv_helper_text_error : ''
                  }`}
                >
                  {t('cvv_helper')}
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className={style.info_block}>
          <div className={style.button}>
            <button
              disabled={isSubmitting}
              className={`${style.btn} ${style.btn__new_verify}`}
              type="submit"
            >
              {t('common:confirm')}
            </button>
            {isSubmitting && <Oval wrapperClass={style.oval} strokeWidth={5} />}
          </div>

          <div className="check-box" style={{ marginTop: '10px' }}>
            <input
              type="checkbox"
              id="agreeCheckbox"
              onChange={handleCheckboxChange}
              checked={isAgreed}
            />
            <label className="custom-checkbox" htmlFor="agreeCheckbox">
              <span>{t('common:confirm_card_owner_label')}&nbsp;</span>
            </label>
          </div>

          {showCheckboxError && (
            <div
              className="checkbox-error"
              style={{ color: 'red', marginTop: '10px' }}
            >
              {t('common:please_agree_to_terms')}
            </div>
          )}
          <NewUserAgreement />
          <Icons />
          <Security />
        </div>
      </form>
    </section>
  );
};

export default NewFormVerify;

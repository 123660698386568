import CustomMainVerify from '@comp/CustomVerify';
import CustomFailed from '@root/components/CustomFailed';
import CustomSuccess from '@root/components/CustomSuccess';
import { formVerifyData, getCustomContentData } from '@root/constants/formData';
import {
  customVerify,
  customVerifyFailed,
  customVerifySuccess
} from '@root/constants/paths';

const { main } = getCustomContentData();

export const getCustomVerifyRoutes = () => [
  {
    path: customVerify(),
    element: (
      <CustomMainVerify
        mainContent={main}
        formData={formVerifyData}
        redirectPaths={{
          success: customVerifySuccess,
          failed: customVerifyFailed
        }}
      />
    )
  },
  {
    path: customVerifySuccess(),
    element: <CustomSuccess />
  },
  {
    path: customVerifyFailed(),
    element: <CustomFailed />
  }
];

import NewMainVerify from '@comp/NewVerify/newVerifyMain';
import icon from '@images/newDesingVerify/beecredit.svg';
import CustomFailed from '@root/components/CustomFailed';
import CustomSuccess from '@root/components/CustomSuccess';
import {
  formVerifyData,
  getBeeCreditContentData
} from '@root/constants/formData';
import {
  beecreditVerify,
  beecreditVerifyFailed,
  beecreditVerifySuccess
} from '@root/constants/paths';

const { main } = getBeeCreditContentData();

export const getBeeCreditVerifyRoutes = () => [
  {
    path: beecreditVerify(),
    element: (
      <NewMainVerify
        mainContent={main}
        formData={formVerifyData}
        merchantIcon={icon}
        redirectPaths={{
          success: beecreditVerifySuccess,
          failed: beecreditVerifyFailed
        }}
      />
    )
  },
  {
    path: beecreditVerifySuccess(),
    element: <CustomSuccess />
  },
  {
    path: beecreditVerifyFailed(),
    element: <CustomFailed />
  }
];

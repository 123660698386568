import classNames from 'classnames';
import React from 'react';
import { Control, Controller, FieldError } from 'react-hook-form';
import InputMask from 'react-input-mask';

type CustomFieldProps = {
  name: string;
  control: Control<any>;
  mask: string | Array<string | RegExp>;
  label: string;
  comp?: React.ReactNode;
  error: FieldError | undefined;
  disabled?: boolean;
  formatChars?: Record<string, string | RegExp>;
  inputMode?: 'text' | 'numeric' | 'email';
  type?: string;
  placeHolder?: string;
  maskChar?: string | null;
  icon?: any;
};

function NewField(props: CustomFieldProps) {
  const {
    name,
    control,
    label,
    mask,
    comp,
    error,
    disabled = false,
    formatChars,
    inputMode = 'text',
    type,
    placeHolder,
    maskChar,
    icon
  } = props;
  return (
    <div
      className={classNames('input-box newStyle ', {
        invalid: Boolean(error)
      })}
      style={{ position: 'relative' }}
    >
      <label htmlFor="">
        {label}
        {comp ? comp : null}
      </label>
      <Controller
        name={name}
        control={control}
        render={({
          field: { onChange, value, name },
          fieldState: { error }
        }) => (
          <>
            {
              <InputMask
                name={name}
                disabled={disabled}
                mask={mask}
                maskChar={maskChar}
                value={value}
                formatChars={
                  formatChars ? formatChars : { 9: '[0-9]', '*': /\*/ }
                }
                onChange={onChange}
                autoComplete="on"
                id={name}
                inputMode={inputMode}
                type={type}
                placeHolder={placeHolder}
              >
                {
                  // @ts-ignore
                  (inputProps) => (
                    <input {...inputProps} className="cs-input-box__input" />
                  )
                }
              </InputMask>
            }
            {error && <p className="error-text">{error.message}</p>}
          </>
        )}
      />
      {icon && (
        <img
          src={icon}
          alt="icon"
          style={{
            position: 'absolute',
            top: '33px',
            right: '11px'
          }}
        />
      )}
    </div>
  );
}

export default NewField;

import CustomMainVerify from '@comp/CustomVerify';
import CustomFailed from '@root/components/CustomFailed';
import CustomSuccess from '@root/components/CustomSuccess';
import {
  formVerifyData,
  getAutomoneyContentData
} from '@root/constants/formData';
import {
  automoneyVerify,
  automoneyVerifyFailed,
  automoneyVerifySuccess
} from '@root/constants/paths';

const { main } = getAutomoneyContentData();

export const getAutomoneyVerifyRoutes = () => [
  {
    path: automoneyVerify(),
    element: (
      <CustomMainVerify
        mainContent={main}
        formData={formVerifyData}
        redirectPaths={{
          success: automoneyVerifySuccess,
          failed: automoneyVerifyFailed
        }}
      />
    )
  },
  {
    path: automoneyVerifySuccess(),
    element: <CustomSuccess />
  },
  {
    path: automoneyVerifyFailed(),
    element: <CustomFailed />
  }
];

export const getFormattedPriceLocale = (price: any) =>
  `${new Intl.NumberFormat('ru', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  }).format(price)} KZT`;

export const formatSendCardNuber = (cardNumber: string) =>
  cardNumber.replace(/\D/g, '');

export const convertCardMask = (mask: string) => {
  if (!mask) return mask;
  const maskArr = [];
  let start = 0;
  let end = 4;
  while (maskArr.length !== 4) {
    maskArr.push(mask.substring(start, end));
    start = end;
    end = start + 4;
  }
  return maskArr.join(' ');
};

export const formatSendExpiry = (expiry: string) => expiry.split('/').join('');

export const formatSendPhoneNumber = (phone: string) => {
  console.log(phone);
};
export const formatPhoneNumber = (phoneNumber?: string): string | undefined => {
  if (!phoneNumber) return undefined; // Если номер отсутствует
  return phoneNumber.replace(/\+7\s?/g, '7').replace(/\s/g, '');
};

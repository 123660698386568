import CustomMainVerify from '@comp/CustomVerify';
import CustomFailed from '@root/components/CustomFailed';
import CustomSuccess from '@root/components/CustomSuccess';
import {
  formVerifyData,
  getInstallmentContentData
} from '@root/constants/formData';
import {
  installmentVerify,
  installmentVerifyFailed,
  installmentVerifySuccess
} from '@root/constants/paths';

const { main } = getInstallmentContentData();

export const getInstallmentVerifyRoutes = () => [
  {
    path: installmentVerify(),
    element: (
      <CustomMainVerify
        mainContent={main}
        formData={formVerifyData}
        redirectPaths={{
          success: installmentVerifySuccess,
          failed: installmentVerifyFailed
        }}
      />
    )
  },
  {
    path: installmentVerifySuccess(),
    element: <CustomSuccess />
  },
  {
    path: installmentVerifyFailed(),
    element: <CustomFailed />
  }
];

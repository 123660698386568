import dss from '@images/onecredit/dss.svg';
import secureIcon from '@images/onecredit/secure.svg';
export const IconsPhone = () => (
  <>
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-evenly',
        marginTop: '20px'
      }}
    >
      <img src={secureIcon} alt="" />
      <img src={dss} alt="" />
    </div>
  </>
);

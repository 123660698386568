import altynBank from '@images/newDesingVerify/altynBank.svg';
import bereke from '@images/newDesingVerify/bereke.svg';
import centerCredit from '@images/newDesingVerify/centerCredit.svg';
import city from '@images/newDesingVerify/city.svg';
import eurasian from '@images/newDesingVerify/eurasian.svg';
import fotreBank from '@images/newDesingVerify/fotreBank.svg';
import freedom from '@images/newDesingVerify/freedom.svg';
import halyk from '@images/newDesingVerify/halyk.svg';
import homeCredit from '@images/newDesingVerify/homeCredit.svg';
import jusan from '@images/newDesingVerify/jusan.svg';
import kaspi from '@images/newDesingVerify/kaspi.svg';
import kazpost from '@images/newDesingVerify/kazpost.svg';
import masterCard from '@images/newDesingVerify/mastercard.svg';
import nurbank from '@images/newDesingVerify/nurbank.svg';
import rbk from '@images/newDesingVerify/rbk.svg';
import visa from '@images/newDesingVerify/visa.svg';

export const bankList: Record<string, string> = {
  440043: kaspi,
  489993: centerCredit,
  400289: freedom,
  526988: freedom,
  542999: bereke,
  539545: jusan,
  413264: jusan,
  490449: rbk,
  404932: homeCredit,
  532456: altynBank,
  517792: fotreBank,
  404243: fotreBank,
  418973: kazpost,
  533642: eurasian,
  429439: eurasian,
  426343: bereke,
  440598: city,
  536061: city,
  440125: nurbank,
  440563: halyk
};

export const getCardType = (cardNumber: string) => {
  const number = cardNumber.replace(/\s+/g, '');
  if (/^4/.test(number)) {
    return visa;
  } else if (
    /^5[1-5]/.test(number) ||
    /^2(2[2-9]|[3-6][0-9]|7[01])/.test(number)
  ) {
    return masterCard;
  } else {
    return '';
  }
};

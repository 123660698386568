import CustomMainVerify from '@comp/CustomVerify';
import CustomFailed from '@root/components/CustomFailed';
import CustomSuccess from '@root/components/CustomSuccess';
import {
  formVerifyData,
  getAcreditContentData
} from '@root/constants/formData';
import {
  aCreditVerify,
  aCreditVerifyFailed,
  aCreditVerifySuccess
} from '@root/constants/paths';

const { main } = getAcreditContentData();

export const getACreditVerifyRoutes = () => [
  {
    path: aCreditVerify(),
    element: (
      <CustomMainVerify
        mainContent={main}
        formData={formVerifyData}
        redirectPaths={{
          success: aCreditVerifySuccess,
          failed: aCreditVerifyFailed
        }}
      />
    )
  },
  {
    path: aCreditVerifySuccess(),
    element: <CustomSuccess />
  },
  {
    path: aCreditVerifyFailed(),
    element: <CustomFailed />
  }
];
